<template>
    <div id="clubSecondDetail">
        <CSBreadcrumb/>
        <div class="filter-panel">
            <input
                type="text"
                class="cs-input"
                style="width:200px"
                v-model="queryParams.search"
                placeholder="搜索监控编号/位置"
            />
            <button
                type="button"
                class="btn btn-primary btn-sm"
                @click="queryCamera()"
            >
                查询
            </button>
        </div>
        <div class="result-panel">
            <CSTable :thead-top="60">
                <template v-slot:thead>
                    <tr>
                        <th>添加时间</th>
                        <th>监控编号</th>
                        <th>位置</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr v-for="camera in cameraInfoList"
                        :key="camera.id"
                    >
                        <td>{{camera.createTime}}</td>
                        <td>
                            <span
                                class="allow-click"
                                @click="checkCameraDetail(camera.id)"
                            >
                                {{camera.name}}
                            </span>
                        </td>
                        <td>{{camera.location}}</td>
                    </tr>
                </template>
            </CSTable>
            <Pagination></Pagination>
        </div>
        <CameraControls>
            <template v-slot:video>
                <video ref="video" src="" @click="changeCameraVideoPlayStatus(cameraId)"></video>
<!--                <div>
                    <div class="pause-layer" v-if="!videoPlayers[camera.id]">
                        <div>
                            <img vue-router-keep-alive-helper="../../assets/icon/videoIcon.png" alt="">
                            点击查看实时画面
                        </div>
                    </div>
                    <video ref="video" vue-router-keep-alive-helper="" @click="changeCameraVideoPlayStatus(cameraId)"></video>
                </div>-->
            </template>
        </CameraControls>
    </div>
</template>

<script>
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import Pagination from "@/components/Pagination";
import {closeRealTimeStreamUrl, openRealTimeStreamUrl, queryCameraListUrl} from "@/requestUrl";
import {initStreamVideoPlayer} from "@/utils";
import CameraControls from "@/components/SecurityCamera/cameraControls";
import CSTable from "@/components/common/CSTable";
export default {
    components:{
        CSTable,
        CameraControls,
        CSBreadcrumb,
        Pagination,
    },
    props: {
      id: Number,
    },
    data(){
        return {
            queryParams:{
                groupId: -1,  //相机组id   查全部传-1
                search: "", //搜索框内容
                untreatedCount: -1 //是否只查看异常未处理   1是  -1所有
            },
            cameraInfoList: [],
            videoPlayers: null,
            cameraId: '',
        }
    },
    mounted() {
        this.queryParams.groupId = this.id;
        this.queryCamera();
        this.$vc.on(this.$route.path, "pagination_page", "event", page => {
            this.queryCamera(page);
        })
    },
    methods:{
        /**
         * 更改视频播放状态
         * @param {Number} id 视频id
         * */
        async changeCameraVideoPlayStatus(id) {
            if (!this.videoPlayers) {
                const player = await this.queryCameraStreamUrl(id);
                if (!player) {
                    this.$vc.message('打开实时视频失败');
                    return ;
                }
                player.play();
                this.videoPlayers = player;
            } else {
                this.videoPlayers.pause();
                this.videoPlayers = null;
            }
        },
        // 获取摄像头
        queryCamera(pageNo = 1, pageSize = 10) {
            this.$fly.post(queryCameraListUrl, {
                ...this.queryParams,
                pageNo,         //页数
                pageSize,       //页面大小
            })
                .then(async res => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.$vc.emit(this.$route.path, "pagination", "init", {
                        total: res?.data?.total || 0,
                        pageSize,
                        pageNo,
                    })
                    this.cameraInfoList = res.data.datas;
                })
        },
        fullScreen(target, act = 'requestFullscreen', el) {
            const cameraWindow = el || document.querySelector(".cameraWindow" + target?.id);
            if (act === 'requestFullscreen') {
                document.body.requestFullscreen();
                this.$vc.emit(this.$route.path, "cameraManage", "cameraControls", {target, visible: true});
            }
            if (!cameraWindow) {
                return ;
            }
            document.body.addEventListener('fullscreenchange', e => {
                if (document.fullscreenElement) {
                    cameraWindow.style.position = 'fixed';
                    cameraWindow.style.top = '0';
                    cameraWindow.style.left = '0';
                    cameraWindow.style.right = '0';
                    cameraWindow.style.bottom = '0';
                    cameraWindow.style.width = '100vw';
                    cameraWindow.style.height = '100vh';
                    cameraWindow.style.background = 'rgba(0, 0, 0, 1)';
                    cameraWindow.style.zIndex = '2004';
                    this.isFullscreen = true;
                } else {
                    this.isFullscreen = false;
                    cameraWindow.style.position = 'relative';
                    cameraWindow.style.width = '540px';
                    cameraWindow.style.height = '340px';
                    cameraWindow.style.zIndex = '1';
                    cameraWindow.style.background = 'rgba(0, 0, 0, .2)';
                    this.$vc.emit(this.$route.path, "cameraManage", "cameraControls", {target: null, visible: false});
                }
            })

        },
        /**
         * 列表查看全屏监控
         * @param {Number} cameraId 监控id
         * */
        checkCameraDetail(cameraId) {
            console.log(this.$refs.video, '监控摄像头');
            this.cameraId = cameraId;
            this.queryCameraStreamUrl(cameraId, this.$refs.video);
            this.fullScreen({id: cameraId}, 'requestFullscreen', this.$refs.video);
        },
        /**
         * 关闭视频流
         * @param {Number} id 监控id
         * */
        closeCameraStream(id) {
            try {
                this.videoPlayers[id].pause();
            } catch (err) {
                console.log(err, '暂停视频错误');
            }
            return this.$fly.get(closeRealTimeStreamUrl, {id}).then(res => res.data || '')
        },
        /**
         * 获取视频流地址
         * @param {Number} id 监控id
         * @param {String} videoEl 显示监控的标签id或者元素
         * */
        async queryCameraStreamUrl(id, videoEl) {
            const data = await this.$fly.get(openRealTimeStreamUrl, {id});
            const cameraStreamUrl = data.data;
            try {
                console.log('开始调用初始化监控函数', id);
                this.videoPlayers = await initStreamVideoPlayer((err) => {
                        console.log(err, '监控的错误信息')
                    }, videoEl ? videoEl : `camera_${id}`,
                    {
                        url: cameraStreamUrl
                    }
                );
            } catch (err) {
                console.log(err, '初始化监控错误信息');
            }
        },
        checkVideo(){
            console.log("checkVideo");
        }
    }
}
</script>

<style>

</style>
